import React, { useEffect }  from "react"
import SEO from "../components/seo"
import { navigate } from "gatsby"

const SignedOutPage = () => {
  
  const handleSignOut = () => {
    navigate('/')
  }

  useEffect(handleSignOut, [])

  return(
    <SEO title="Signing Out..." />
  )
}

export default SignedOutPage
